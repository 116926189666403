import runIcon from '@img/run.png';
import walkIcon from '@img/walk.png';
import hikeIcon from '@img/hike.png';
import rockIcon from '@img/rock.png';
import rowingIcon from '@img/rowing.png';
import kayakIcon from '@img/kayak.png';
import canoeIcon from '@img/canoe.png';
import cyclingIcon from '@img/cycling.png';
import drawIcon from '@img/draw.png';
import yogaIcon from '@img/yoga.png';
import sewIcon from '@img/sew.png';
import poemIcon from '@img/poem.png';
import flowerIcon from '@img/flower.png';
import plantIcon from '@img/plant.png';
import bakeIcon from '@img/bake.png';
import otherIcon from '@img/other.png';
import kindIcon from '@img/kind-lg.png';
import donorIcon from '@img/donor-lg.png';
import motivatorIcon from '@img/motivator-lg.png';
import { LOCAL_STORAGE_KEYS } from './storage';

export const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
export const SESSION_ERRORS = ['Expired Token', 'Invalid Token', 'Invalid code', 'Invalid Params', 'Unauthorized'];
export const ADVICE_CONTEXTS = {
  1: 'ADVICE_FOR_JUST_DIAGNOSED_PEOPLE',
  2: 'ADVICE_FOR_IN_TREATMENT_PEOPLE',
  3: 'ADVICE_FOR_TREATMENT_COMPLETED_PEOPLE',
};

export const DEFAULT_ACTION_RESULT = {
  type: '',
  loading: false,
  status: false,
  message: '',
};

export const SLICE_NAMES = {
  INIT: 'init',
  AUTH: 'auth',
  USER: 'user',
  HOME: 'home',
  NOTIFICATIONS: 'notifications',
  MY_SPACE: 'mySpace',
  SURVEY: 'survey',
  SMILE: 'smile',
  DICTIONARIES: 'dictionaries',
  DASHBOARD: 'dashboard',
  ADVISOR: 'advisor',
};

export const DEFAULT_STATES = {
  AUTH: {
    actionResult: DEFAULT_ACTION_RESULT,
    emailExists: false,
    token: localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
    user: null,
  },
  USER: {
    actionResult: DEFAULT_ACTION_RESULT,
    advices: [],
  },
  HOME: {
    actionResult: DEFAULT_ACTION_RESULT,
    data: {},
  },
  NOTIFICATIONS: {
    actionResult: DEFAULT_ACTION_RESULT,
    notifications: [],
    newNotifyCount: null,
  },
  MY_SPACE: {
    actionResult: DEFAULT_ACTION_RESULT,
    collection: [],
    articles: [],
    gallery: [],
    currentArticle: {},
    comments: [],
    categories: [],
    currentCommentsNodeId: '',
  },
  SURVEY: {
    actionResult: DEFAULT_ACTION_RESULT,
    surveys: [],
    currentSurveyInfo: {},
    currentSurveyResult: null,
    currentSurvey: {},
    finished: 0,
    experienceAsYou: null,
  },
  SMILE: {
    actionResult: DEFAULT_ACTION_RESULT,
    activities: [
      {
        id: 1,
        icon: runIcon,
        title: 'Run',
      },
      {
        id: 2,
        icon: walkIcon,
        title: 'Walk',
      },
      {
        id: 3,
        icon: hikeIcon,
        title: 'Hike',
      },
      {
        id: 4,
        icon: rockIcon,
        title: 'Rock Climb',
      },
      {
        id: 5,
        icon: rowingIcon,
        title: 'Rowing',
      },
      {
        id: 6,
        icon: kayakIcon,
        title: 'Kayak',
      },
      {
        id: 7,
        icon: canoeIcon,
        title: 'Canoe',
      },
      {
        id: 8,
        icon: cyclingIcon,
        title: 'Cycling',
      },
      {
        id: 9,
        icon: drawIcon,
        title: 'Draw',
      },
      {
        id: 10,
        icon: yogaIcon,
        title: 'Yoga',
      },
      {
        id: 11,
        icon: sewIcon,
        title: 'Sew',
      },
      {
        id: 12,
        icon: poemIcon,
        title: 'Write a poem',
      },
      {
        id: 13,
        icon: flowerIcon,
        title: 'Plant a flower',
      },
      {
        id: 14,
        icon: plantIcon,
        title: 'Plant a tree',
      },
      {
        id: 15,
        icon: bakeIcon,
        title: 'Bake',
      },
      {
        id: 16,
        icon: otherIcon,
        title: 'Other',
      },
    ],
    amount: {
      sent: 0,
      received: 0,
    },
    file: '',
  },
  DICTIONARIES: {
    actionResult: DEFAULT_ACTION_RESULT,
    countries: [],
    userCountries: [],
    diagnoses: [],
    currentTreatment: null,
    sideEffectTreatments: [],
    ageGroups: [],
    conditions: [],
    clinics: [],
    privacyPolicy: '',
    terms: '',
  },
  ADVISOR: {
    actionResult: DEFAULT_ACTION_RESULT,
    symptoms: [],
    symptomDetails: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SYMPTOM_RECOMMENDATIONS)),
    intro: null,
  },
  DASHBOARD: {
    actionResult: DEFAULT_ACTION_RESULT,
    levels: [
      {
        id: 1,
        name: 'Kind heart',
        icon: kindIcon,
        min: 0,
        max: 50,
      },
      {
        id: 2,
        name: 'Donor of Smile',
        icon: donorIcon,
        min: 51,
        max: 150,
      },
      {
        id: 3,
        name: 'Motivator',
        icon: motivatorIcon,
        min: 151,
        max: 200,
      },
    ],
  },
};
