import {
  getCountries,
  getUserCountries,
  getDiagnoses,
  getTreatment,
  getSideEffectTreatments,
  getAgeGroups,
  getConditions,
  getClinics,
  getPrivacyPolicy,
  getTerms,
} from '@redux/dictionaries/thunks';

const thunksConfig = [
  {
    type: 'getCountries',
    thunk: getCountries,
    callbacks: {
      fulfilled: (state, action) => {
        state.countries = action.payload;
      },
    },
  },
  {
    type: 'getUserCountries',
    thunk: getUserCountries,
    callbacks: {
      fulfilled: (state, action) => {
        state.userCountries = action.payload;
      },
    },
  },
  {
    type: 'getDiagnoses',
    thunk: getDiagnoses,
    callbacks: {
      fulfilled: (state, action) => {
        state.diagnoses = action.payload;
      },
    },
  },
  {
    type: 'getTreatment',
    thunk: getTreatment,
    callbacks: {
      fulfilled: (state, action) => {
        state.currentTreatment = action.payload;
      },
    },
  },
  {
    type: 'getSideEffectTreatments',
    thunk: getSideEffectTreatments,
    callbacks: {
      fulfilled: (state, action) => {
        state.sideEffectTreatments = action.payload;
      },
    },
  },
  {
    type: 'gerAgeGroups',
    thunk: getAgeGroups,
    callbacks: {
      fulfilled: (state, action) => {
        state.ageGroups = action.payload;
      },
    },
  },
  {
    type: 'getConditions',
    thunk: getConditions,
    callbacks: {
      fulfilled: (state, action) => {
        state.conditions = action.payload;
      },
    },
  },
  {
    type: 'getClinics',
    thunk: getClinics,
    callbacks: {
      fulfilled: (state, action) => {
        state.clinics = action.payload;
      },
    },
  },
  {
    type: 'getPrivacyPolicy',
    thunk: getPrivacyPolicy,
    callbacks: {
      fulfilled: (state, action) => {
        state.privacyPolicy = action.payload?.text;
      },
    },
  },
  {
    type: 'getTerms',
    thunk: getTerms,
    callbacks: {
      fulfilled: (state, action) => {
        state.terms = action.payload?.text;
      },
    },
  },
];

export default thunksConfig;
