import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getMySpaceArticles = createAsyncThunk(
  'mySpace/getMySpaceArticles',
  async (params) => {
    const articlesResponse = await request({ method: 'get', url: '/my-space/info-room/articles', params });
    return articlesResponse.data;
  },
);

export const getMySpaceCollection = createAsyncThunk(
  'mySpace/getMySpaceCollection',
  async () => {
    const collectionsResponse = await request({ method: 'get', url: '/my-space/us' });
    return collectionsResponse.data;
  },
);

export const getMySpaceGallery = createAsyncThunk(
  'mySpace/getMySpaceGallery',
  async () => {
    const galleryResponse = await request({ method: 'get', url: '/my-space/sea-of-tranquility' });
    return galleryResponse.data;
  },
);

export const getMySpaceArticle = createAsyncThunk(
  'mySpace/getMySpaceArticle',
  async (articleId) => {
    const articleResponse = await request({ method: 'get', url: `/my-space/info-room/articles/${articleId}` });
    return articleResponse.data;
  },
);

export const getComments = createAsyncThunk(
  'mySpace/getComments',
  async (nodeId) => {
    const commentsResponse = await request({ method: 'get', url: `/comments/node/${nodeId}` });
    return commentsResponse.data;
  },
);

export const sendComment = createAsyncThunk(
  'mySpace/sendComment',
  async (data) => {
    const commentResponse = await request({ method: 'post', url: `/comments/node/${data.id}`, data: { body: data.body } });
    return commentResponse.data;
  },
);

export const toggleLike = createAsyncThunk(
  'mySpace/toggleLike',
  async (nodeId) => {
    const likeResponse = await request({ method: 'post', url: `/likes/node/${nodeId}` });
    return likeResponse.data;
  },
);

export const getArticlesCategories = createAsyncThunk(
  'dictionaries/getArticlesCategories',
  async () => {
    const categoriesResponse = await request({ method: 'get', url: '/my-space/info-room/articles/categories' });
    return categoriesResponse.data;
  },
);
