import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const dedicateSmile = createAsyncThunk(
  'smile/dedicate',
  async (data) => {
    const dedicateResponse = await request({ method: 'post', url: '/smile/dedicate', data });
    return dedicateResponse.data;
  },
);

export const thankYouForSmile = createAsyncThunk(
  'smile/thankYouForSmile',
  async (smileId) => {
    const thankYouResponse = await request({ method: 'patch', url: `/smile/thankYouForSmile/${smileId}` });
    return thankYouResponse.data;
  },
);

export const getSmileAmount = createAsyncThunk(
  'smile/getSmileAmount',
  async () => {
    const smileResponse = await request({ method: 'get', url: '/smile/amount/' });
    return smileResponse.data;
  },
);
