import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@services/Request';

export const getAllSurveys = createAsyncThunk(
  'survey/getAllSurveys',
  async () => {
    const surveysResponse = await request({ method: 'get', url: '/survey/list' });
    return surveysResponse.data;
  },
);

export const getSurveyInfo = createAsyncThunk(
  'survey/getSurveyInfo',
  async (surveyId) => {
    const infoResponse = await request({ method: 'get', url: `/survey/info/${surveyId}` });
    return infoResponse.data;
  },
);

export const getSurvey = createAsyncThunk(
  'survey/getSurvey',
  async (surveyId) => {
    const surveyResponse = await request({ method: 'get', url: `/survey/${surveyId}` });
    return surveyResponse.data;
  },
);

export const saveSurveyAnswers = createAsyncThunk(
  'survey/saveSurveyAnswers',
  async (data) => {
    const answersResponse = await request({ method: 'post', url: '/survey/result', data });
    return answersResponse.data;
  },
);

export const getFinishedSurveysCount = createAsyncThunk(
  'survey/getFinishedSurveysCount',
  async () => {
    const countResponse = await request({ method: 'get', url: '/survey/count-of-done' });
    return countResponse.data;
  },
);

export const getExperienceAsYou = createAsyncThunk(
  'survey/getExperienceAsYou',
  async () => {
    const experienceResponse = await request({ method: 'get', url: '/survey/experience-as-you' });
    return experienceResponse.data;
  },
);
